<template>
    <div class="main-container">
      <h5 class="pageTitle">드론 타입 수정/삭제</h5>
  
      <div class="form-container">
        <!-- 모델 -->
        <div class="form-group">
          <label for="model">모델(타입)</label>
          <input v-model="form.model" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.model">{{ errors.model }}</p>
        </div>
  
        <!-- 타입 -->
        <!-- <div class="form-group">
          <label for="type">타입</label>
          <input v-model="form.type" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.type">{{ errors.type }}</p>
        </div> -->
  
        <!-- 타입이름 -->
        <!-- <div class="form-group">
          <label for="name">타입이름</label>
          <input v-model="form.name" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.name">{{ errors.name }}</p>
        </div> -->
  
        <!-- 순서 -->
        <div class="form-group">
          <label for="order">순서</label>
          <input v-model="form.order" type="number" class="input-box" min="1" @input="validateForm" />
          <p class="error-message" v-if="errors.order">{{ errors.order }}</p>
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateDroneType" :disabled="hasErrors">수정</button>
          <button class="button-delete" @click="deleteDroneType">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "DroneTypeUpdateDelete",
    data() {
      return {
        form: {
          uuid: "",
          model: "",
          type: "",
          name: "",
          order: "",
        },
        errors: {
          model: "",
          type: "",
          name: "",
          order: "",
        },
        originalOrder: null, // 기존 order 값
        allOrders: [], // 전체 order 목록
      };
    },
    computed: {
      hasErrors() {
        return Object.values(this.errors).some((error) => error);
      },
    },
    mounted() {
      this.fetchDroneType();
      this.fetchAllOrders();
    },
    methods: {
      // ✅ 드론 타입 조회 (수정할 데이터 가져오기)
      async fetchDroneType() {
        try {
          const token = this.getToken();
          const uuid = this.$route.params.id;
          const response = await axios.get(`/master/drone-type/${uuid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (response.data) {
            this.form = response.data;
            this.originalOrder = parseInt(response.data.order, 10); // 기존 순서 저장
          }
        } catch (error) {
          console.error("드론 타입 조회 실패:", error);
          Swal.fire("오류", "드론 타입 정보를 불러오지 못했습니다.", "error");
        }
      },
  
      // ✅ 전체 order 목록 가져오기 (중복 체크를 위해)
      async fetchAllOrders() {
        try {
          const token = this.getToken();
          const response = await axios.get("/master/drone-type", {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (response.data) {
            this.allOrders = response.data.map((item) => parseInt(item.order, 10)).filter((num) => !isNaN(num));
          }
        } catch (error) {
          console.error("전체 order 조회 실패:", error);
        }
      },
  
      // ✅ 입력값 검증 (실시간 반영)
      validateForm() {
        this.errors = { model: "", type: "", name: "", order: "" };
  
        if (!this.form.model) this.errors.model = "모델명(타입)을 입력하세요.";
        // if (!this.form.type) this.errors.type = "타입을 입력하세요.";
        // if (!this.form.name) this.errors.name = "타입 이름을 입력하세요.";
  
        const orderNumber = parseInt(this.form.order, 10);
        if (isNaN(orderNumber) || orderNumber <= 0) {
          this.errors.order = "올바른 순서 값을 입력하세요.";
        } else if (orderNumber !== this.originalOrder && this.allOrders.includes(orderNumber)) {
          this.errors.order = `이미 존재하는 순서(${orderNumber})입니다.`;
        }
        this.form.name = this.form.model;
      },
  
      // ✅ 드론 타입 수정
      async updateDroneType() {
        this.validateForm();
        if (this.hasErrors) return;
  
        try {
          const token = this.getToken();
          const uuid = this.form.uuid;
  
          await axios.put(`/master/drone-type/${uuid}`, this.form, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          Swal.fire("성공", "드론 타입이 수정되었습니다.", "success");
          this.goToList();
        } catch (error) {
          console.error("수정 실패:", error);
          Swal.fire("오류", "수정 중 문제가 발생했습니다.", "error");
        }
      },
  
      // ✅ 드론 타입 삭제
      async deleteDroneType() {
        try {
          const token = this.getToken();
          const uuid = this.form.uuid;
  
          const confirm = await Swal.fire({
            title: "삭제 확인",
            text: "정말로 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "삭제",
            cancelButtonText: "취소",
          });
  
          if (confirm.isConfirmed) {
            await axios.delete(`/master/drone-type/${uuid}`, {
              headers: { Authorization: `Bearer ${token}` },
            });
  
            Swal.fire("삭제됨", "드론 타입이 삭제되었습니다.", "success");
            this.goToList();
          }
        } catch (error) {
          console.error("삭제 실패:", error);
          Swal.fire("오류", "삭제 중 문제가 발생했습니다.", "error");
        }
      },
  
      // ✅ 토큰 가져오기
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData?.access_token || "";
      },
  
      // ✅ 목록 페이지 이동
      goToList() {
        this.$router.push("/master-droneTypeRead");
      },
    },
  };
  </script>
  
  <style scoped>
/* 동일한 스타일 유지 */
.main-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageTitle {
  font-weight: bold;
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 70%;
  max-width: 800px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-box,
.select-box {
  flex: 1;
  padding: 8px;
  margin-right: 40px;
  border: 1px solid rgba(130, 128, 128, 0.26);
  border-radius: 3px;
  max-width: 300px;
}

.input-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

.button-list {
  background-color: #ff9800;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-update {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-delete {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
</style>
  